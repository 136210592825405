import React, { useMemo } from 'react';
import * as d3 from 'd3';
import { polygonHull } from 'd3-polygon';

const HexModule = ({ data, xScale, yScale, inverseScale }) => {
  // Define a color palette of 20 easily distinguishable colors with low alpha
  const colorPalette = [
    'rgba(54, 162, 235, 0.3)',
    'rgba(0, 255, 127, 0.2)',
    'rgba(203, 255, 169,0.5)',
    'rgba(240, 230, 140, 0.3)',
    'rgba(246, 245, 242,0.8)',
    'rgba(255, 239, 239,0.8)',
    'rgb(212, 226, 212,0.8)',
    'rgba(230, 255, 253,0.9)',
    'rgba(255, 191, 169,0.6)',
    'rgba(251, 255, 177,0.6)',
    'rgba(255, 209, 227,0.8)',
    'rgba(229, 224, 255,0.8)',
    'rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.3)', 'rgba(255, 206, 86, 0.3)',
    'rgba(75, 192, 192, 0.8)', 'rgba(153, 102, 255, 0.3)', 'rgba(255, 159, 64, 0.3)',
    'rgba(255, 105, 180, 0.5)', 'rgba(139, 69, 19, 0.3)', 'rgba(0, 128, 128, 0.3)',
    'rgba(70, 130, 180, 0.3)', 'rgba(0, 255, 127, 0.2)', 'rgba(255, 140, 0, 0.3)',
    'rgba(220, 20, 60, 0.3)', 'rgba(34, 139, 34, 0.3)', 'rgba(218, 112, 214, 0.3)',
    'rgba(255, 69, 0, 0.3)', 'rgba(154, 205, 50, 0.3)', 'rgba(46, 139, 87, 0.3)',
    'rgba(123, 104, 238, 0.3)',
  ];

  // Group data by module_id
  const groupedData = useMemo(() => {
    return d3.group(data, d => d.module_id);
  }, [data]);

  // Calculate hulls for each group
  const hulls = useMemo(() => {
    return Array.from(groupedData).map(([module_id, points], index) => {
      const pointArray = points.map(point => [xScale(point.x), yScale(point.y)]);
      const hull = polygonHull(pointArray);
      return { module_id, hull, points, color: colorPalette[index % 20] }; // Assign color based on index modulo 20
    });
  }, [groupedData, xScale, yScale]);

  return (
    <g>
      {hulls.map((hullGroup, index) => (
        <g key={index}>
          {/* Draw the convex hull if it exists */}
          {hullGroup.hull && (
            <path
              d={d3.line()
                .x(d => d[0])
                .y(d => d[1])
                .curve(d3.curveCatmullRomClosed)(hullGroup.hull)}
              fill={hullGroup.color} // Use the assigned color
              stroke="lightblue"
              strokeWidth={2 * inverseScale}
              strokeDasharray="8,6"
            />
          )}

          {/* Optionally render circles or other visual elements for each point */}
          {/* hullGroup.points.map((point, idx) => (
            <circle
              key={idx}
              cx={xScale(point.x)}
              cy={yScale(point.y)}
              r={5}
              fill="red"
            />
          )) */}
        </g>
      ))}
    </g>
  );
};

export default HexModule;
